<template>
  <div class="box" style="">
    <div class="banner">
      <div class="box">
        <div class="left">
          <img src="/img/zhuceleft.png" alt="" />
        </div>
        <div class="right">
          <p class="title-font">用户注册</p>
          <el-form :rules="rules" ref="form" :model="form" class="body-from">
            <el-form-item prop="name">
              <el-input placeholder="请输入车主姓名" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-col :span="15">
                <el-input placeholder="请输入手机号" v-model="form.phone"></el-input>
              </el-col>
              <el-col :span="9">
                <el-button class="code" :disabled="disabled" @click="GetPhoneCode">{{ phoneStr }}</el-button>
              </el-col>
            </el-form-item>
            <el-form-item prop="phoneCode">
              <el-input placeholder="请输入手机验证码" v-model="form.phoneCode"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" placeholder="请输入密码" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item prop="passwordAgin">
              <el-input type="password" placeholder="请再次确认密码" v-model="form.passwordAgin"></el-input>
            </el-form-item>
            <p class="otherRefister">
              <span @click=" clickOtherRefister('/enterpriseRegister/enterpriseRegister')">
                &gt;&gt;&#160;&#160;&#160;&#160;企业用户注册
              </span>
            </p>
            <el-form-item class="refister-ty">
              <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
              <span class="agreement-font" @click="dialogVisible = true">《用户协议手册》</span>
            </el-form-item>
            <el-form-item>
              <el-button @click="jump" class="submit-btn">注册
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" title="用户协议手册" :visible.sync="dialogVisible">
      <Agreement></Agreement>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Agreement from "./agreement";
export default {
  name: "fillInTheOrder",
  components: {
    Agreement,
  },
  computed: {
    ...mapState({
      get_Check: (state) => {
        return state.register.get_Check;
      },
      get_GetPhoneCode: (state) => {
        return state.register.get_GetPhoneCode;
      },
      get_RegisterOwner: (state) => {
        return state.register.get_RegisterOwner;
      },
    }),
  },
  data() {
    var checkUserName = (rule, value, callback) => {
      if (value === undefined) {
        value = "";
      }
      this.$store.dispatch("register/Check", { name: value }).then(() => {
        if (this.get_Check) {
          callback();
          return;
        } else {
          callback("此手机号已注册过");
        }
      });
    };
    var checkPassWord = (rule, value, callback) => {
      if (value === undefined) {
        value = "";
      }
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
        return;
      }
    };
    return {
      form: {
        name: "",
        phone: "",
        phoneCode: "",
        password: "",
        passwordAgin: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        phoneCode: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入11位手机号码",
            trigger: "blur",
          },
          { pattern: /^1[34578]\d{9}$/, message: "请输入正确手机号" },
          { validator: checkUserName, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "密码6~20位",
            trigger: "blur",
          },
          {
            max: 20,
            message: "密码6~20位",
            trigger: "blur",
          },
        ],
        passwordAgin: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "密码6~20位",
            trigger: "blur",
          },
          {
            max: 20,
            message: "密码6~20位",
            trigger: "blur",
          },
          { validator: checkPassWord, trigger: "blur" },
        ],
      },
      radio: "1",
      checked: false,
      phoneStr: "发送短信验证码",
      disabled: false,
      timeStr: 60,
      dialogVisible: false,
    };
  },
  methods: {
    GetPhoneCode() {
      var phone = this.form.phone;
      const reg = /^1[34578]\d{9}$/;
      if (reg.test(phone)) {
        var that = this;
        that.$store
          .dispatch("register/GetPhoneCode", { phone: phone })
          .then(() => {
            if (!that.get_GetPhoneCode.success) {
              that.$message.error("此手机号已注册过");
            } else {
              that.$message.success("验证码已发送");
              that.disabled = true;
              that.phoneStr = that.timeStr;
              that.updatetimeStr();
            }
          });
      } else {
        this.$message.error("请输入正确的手机号");
      }
    },
    updatetimeStr() {
      var that = this;
      setTimeout(function () {
        that.timeStr -= 1;
        that.phoneStr = that.timeStr;
        if (that.timeStr == 0) {
          that.timeStr = 60;
          that.disabled = false;
          that.phoneStr = "发送短信验证码";
        } else {
          that.updatetimeStr();
        }
      }, 1000);
    },
    clickOtherRefister(url) {
      let routeUrl = this.$router.push({
        path: url,
      });
    },
    jump() {
      var that = this;
      that.$refs["form"].validate((valid) => {
        if (valid) {
          if (that.checked) {
            that.$store
              .dispatch("register/RegisterOwner", {
                UserName: that.form.phone,
                Name: that.form.name,
                Password: that.form.password,
                PhoneCode: that.form.phoneCode,
              })
              .then(() => {
                if (that.get_RegisterOwner.success) {
                  that.$message.success("注册成功");
                  that.$router.push({
                    path: "/register/login",
                  });
                } else {
                  that.$message.error(response.result.msg);
                }
              });
          } else {
            that.$message.error("请同意用户协议手册");
            return false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title-font {
  font-size: 24px; /* no */
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #24a2aa;
  text-align: center;
  letter-spacing: 10px; /* no */
  padding: 25px 0 0 0; /* no */
}
.body-from {
  padding: 23px 30px 0 30px; /* no */
}
.agreement-font {
  color: #24a2aa;
  cursor: pointer;
}
.submit-btn {
  background: #24a2aa;
  color: white;
  width: 100%;
  height: 40px; /* no */
}
.banner {
  background-image: url(/img/zhucebanner.png);
  width: 100%; /* no */
  height: 720px; /* no */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 49.22%;
    height: 78.14%;
    display: flex;
    background-color: #f8fcff;
    .left {
      width: 55%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 45%;
      height: 100%;
      background: white;
      border-radius: 0 16px 16px 0; /* no */
    }
  }
}
.right {
  /deep/.el-form-item {
    margin-bottom: 20px; /* no */
  }
  /deep/.refister-ty.el-form-item {
    margin-bottom: 10px; /* no */
  }
  .otherRefister {
    text-align: right;
    color: rgb(36, 162, 170);
    cursor: pointer;
  }
  .code {
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 4px; /* no */
    background: #24a2aa;
    color: #fff;
    width: calc(100% - 4px); /* no */
  }
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #24a2aa;
  background: #24a2aa;
}
</style>
